import config from '../config';
import axios from 'axios';

export const fetchBoxes = async () => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios(config.APPLICATION_URL + 'adminApp/boxes',
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};

export const fetchBuildings = async () => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios(config.APPLICATION_URL + 'adminApp/buildings',
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};


export const fetchBuildingInstallers = async (buildingID) => {
    try {
        const res = await axios(config.APPLICATION_URL + `adminApp/installers/${buildingID}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};


export const fetchBuildingControllers = async (buildingID) => {
    try {
        const res = await axios(config.APPLICATION_URL + `adminApp/controllers/${buildingID}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};


export const fetchResidentAccesses = async (residentID, userID) => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios(config.APPLICATION_URL + `adminApp/accesses/${residentID}/${userID}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};

export const fetchResidents = async () => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios(config.APPLICATION_URL + 'adminApp/residents',
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data;
    } catch (err) {
    }
    return null;
};


export const fetchCustomers = async () => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios(config.APPLICATION_URL + 'adminApp/customers',
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};


export const fetchAllCustomers = async () => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios(config.APPLICATION_URL + 'adminApp/allCustomers',
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};

export const fetchPostBox = async (id) => {
    //const token = "Bearer " + localStorage.token;
    try {

        const res = await axios(config.APPLICATION_URL + `adminApp/box/${id}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data;
    } catch (err) {
    }
    return null;
};

export const fetchBoxSerials = async (buildingID) => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios.get(config.APPLICATION_URL + `adminApp/boxSerials/${buildingID}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};

export const fetchBuilding = async (id) => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios.get(config.APPLICATION_URL + `adminApp/building/${id}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};


export const fetchResident = async (resident_id) => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios.get(config.APPLICATION_URL + `adminApp/resident/${resident_id}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};


export const fetchCustomerBuildings = async (customerID) => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios(config.APPLICATION_URL + `adminApp/customerBuildings/${customerID}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};

export const fetchBuildingBoxes = async (buildingID) => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios.get(config.APPLICATION_URL + `adminApp/buildingBoxes/${buildingID}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};

export const fetchBoxResidents = async (boxID) => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios.get(config.APPLICATION_URL + `adminApp/box/${boxID}/residents`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};

export const fetchUsers = async () => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios.get(config.APPLICATION_URL + 'adminApp/users',
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data;
    } catch (err) {
    }
    return null;
};



export const fetchUser = async (id) => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios.get(config.APPLICATION_URL + `adminApp/user/${id}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};


export const fetchCustomer = async (id) => {
    //const token = "Bearer " + localStorage.token;
    try {
        const res = await axios.get(config.APPLICATION_URL + `adminApp/customer/${id}`,
            {
                headers: {
                    Authorization: localStorage.token
                }
            });
        return res.data
    } catch (err) {
    }
    return null;
};


