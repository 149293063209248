import { Card, Row, Col,  FormControl, Button, Form, FormGroup, Alert } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import Select from 'react-select';
import { fetchBuildings, fetchPostBox, fetchBoxSerials } from '../../../data/dataFetch';
import { updatePostBox, createPostBox, deletePostBox } from '../../../data/dataUpdate';
import { isIterableArray } from "../../../helpers/utils";
import confirm from 'reactstrap-confirm';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify';
const PostBox = () => {

    const { id } = useParams();
    const [isNew, setIsNew] = useState(true);
    const [isEditing, setIsEditing] = useState(true);
    const [initialPostBox, setInitialPostBox] = useState({ building_name: '', box_address: '', building_id: 0, box_serial: '', display_content: '' });
    const [postBox, setPostBox] = useState(initialPostBox);
    const [buildings, setBuildings] = useState(null);
    const [boxSerials, setBoxSerials] = useState(null);
    const history = useHistory();


    useEffect(() => {
        const loadBuildings = async () => {
            setBuildings(await fetchBuildings());
        }
        loadBuildings();
    }, [])

    useEffect(() => {
        const loadPostBox = async () => {
            var data = await fetchPostBox(id);
            var copy = JSON.parse(JSON.stringify(data));
            setIsNew(false);
            setInitialPostBox(copy);
            setPostBox(copy);
            loadBoxSerials(copy.building_id);
        }
        if (id === 'new') {
            setInitialPostBox({ building_name: '', box_address: '', building_id: 0, box_serial: '', display_content: '' });
            setPostBox(initialPostBox);
            setIsNew(true);
        }
        else
            loadPostBox();
        // eslint-disable-next-line
    }, [])

    const isPostBoxUnchanged = () => {
        return (JSON.stringify(initialPostBox) === JSON.stringify(postBox));
    }


    const loadBoxSerials = async (buildingId) => {
        setBoxSerials(await fetchBoxSerials(buildingId));
    }

    const insertOrUpdatePostBox = async () => {
        if (isNew) {
            const result = await createPostBox(postBox);
            if (result){
                history.push(`/`);
            }
            else
                toast.error('Det har oppstått en feil ', result);
        }
        else {
            updatePostBox(postBox);
            setIsEditing(false);
            setInitialPostBox(postBox);
            toast.success('postkasse er oppdatert');
        }
    }

    const removeSerialNumber = async () => {
        postBox.box_serial = null;
        updatePostBox(postBox);
        setIsEditing(false);
        setInitialPostBox(postBox);
    }

    const deleteCurrentPostbox = async (id) => {
        let result = await confirm({
            title: "Bekrefte sletting",
            message: "Bekrefte sletting.",
            confirmText: "Bekrefte",
            cancelText: "Avbryt",
        });
        if (result) {
            await deletePostBox(id);
            history.push(`/`);
        }

    }

    return (
        <div className="mb-5">


            <Card>
                <Row className="align-items-center p-3" >
                    <Col>
                        <h5>{isNew ? "NY" : ""} POSTKASSE {!isNew ? postBox.box_address : ""}</h5>
                    </Col>
                    <Col xs="auto" className="text-right">
                        <Link to={`/`} className="mr-2">
                            <Button variant="primary" size="sm"> &lt; PostKasser</Button>
                        </Link>
                        {!isNew &&
                            < Button
                                onClick={() => { deleteCurrentPostbox(id); }}
                                variant="danger" size="sm">Slett
                            </Button>
                        }
                        {" "}
                        < Button
                            onClick={() => {
                                if (isEditing) {
                                    setPostBox(JSON.parse(JSON.stringify(initialPostBox)));
                                    if (isNew) {
                                        history.push(`/`);
                                    }
                                }
                                setIsEditing(!isEditing);
                            }}
                            variant="warning" size="sm" >Avbryt
                        </Button>
                    </Col>
                </Row>
                <Card.Body>
                    <Form>
                        <FormGroup className="mr-2 mb-2" style={{ minWidth: 200 }}>
                            <Form.Label>Bygning</Form.Label>
                            <Select
                                options={
                                    isIterableArray(buildings) && Object.values(buildings).map((building) => ({ value: building.building_id, label: building.building_name }))
                                }
                                value={({ value: postBox.building_name, label: isIterableArray(buildings) ? (buildings.find(v => v.building_id === Number(postBox.building_id)) ?? {}).building_name : postBox.building_name })}
                                onChange={({ value }) => {
                                    var building = buildings.find(v => v.building_id === Number(value));
                                    setPostBox({ ...postBox, building_id: value, building_name: building.building_name });
                                    loadBoxSerials(value);
                                }}
                            />

                        </FormGroup>
                        <FormGroup className="mr-2 mb-2">
                            <Form.Label>Postkasse-ID</Form.Label>
                            <FormControl
                                value={postBox.box_address}
                                onFocus={(event) => { event.target.select() }}
                                onChange={({ target }) => {
                                    setPostBox({ ...postBox, box_address: target.value })
                                }}
                            />

                        </FormGroup>

                        <FormGroup className="mr-2 mb-2">
                            <Form.Label>Serienummer</Form.Label>
                            <Select
                                options={isIterableArray(boxSerials) && boxSerials.map((serial) => ({ value: serial, label: serial }))}
                                value={({ value: postBox.box_serial, label: postBox.box_serial })}
                                onChange={({ value }) => {
                                    setPostBox({ ...postBox, box_serial: value });
                                }}
                            />
                        </FormGroup>


                        <FormGroup className="mr-2 mb-2">
                            <Form.Label>Tekst på displayet</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={postBox.display_content}
                                onFocus={(event) => { event.target.select() }}
                                onChange={({ target }) => {
                                    setPostBox({ ...postBox, display_content: target.value })
                                }}
                                className="w-100"
                            />

                        </FormGroup>
                    </Form>
                </Card.Body>
                <Row className="align-items-center p-3" >
                    {!isNew &&
                        <>
                            <Col xs="auto" className="text-right">

                                {" "}
                                < Button
                                    onClick={() => { removeSerialNumber(); }}
                                    color="outline-primary" size="sm" >
                                    <FontAwesomeIcon icon="trash" />
                                    Fjern serienummer fra postkassen
                                </Button>
                                {" "}
                            </Col>
                            <Col xs="auto" className="text-right">
                                <Button
                                    as={Link}
                                    color="outline-primary"
                                    size="sm"
                                    to={`/postbox/${id}/add-access`}
                                >
                                    <FontAwesomeIcon icon="plus" />
                                    Legg til korttilgang
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Card>

            {
                isPostBoxUnchanged() ? null : <div className="fixed-top">
                    <Alert color="danger">
                        <Row>
                            <Col sm="auto">
                                <Button onClick={() => insertOrUpdatePostBox()} variant="primary" >
                                    Bruk
                                </Button>
                                {" "}
                                {!isNew &&
                                    <Button variant="danger" >
                                        Tilbakstil
                                    </Button>
                                }
                            </Col>
                            <Col className="mt-2" sm="auto">
                                <span >
                                    Vil du lagre endringene?
                                </span>
                            </Col>
                        </Row>
                    </Alert>
                </div>
            }

        </div >

    );

};
export default PostBox;