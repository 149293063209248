import { Row, Col } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { Button, Input, FormText } from 'reactstrap';
import { useDebounce } from '../../../helpers/utils';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { isIterableArray, getPaginationArray } from '../../../helpers/utils'

const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div>
            <Button className="float-right mb-2" color="success" onClick={handleClick}>Eksporter til CSV</Button>
        </div>
    );
};


const Customers = ({ customers }) => {

    const [columns, setColumns] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [filter, setFilter] = useState();
    const debouncedFilter = useDebounce(filter, 300);
    const history = useHistory();

    useEffect(() => {
        let columns = [
            {
                dataField: 'customer_name',
                text: 'Kundenavn',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
            {
                dataField: 'parent_customer_name',
                text: 'Eier',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
        ];

        setColumns(columns);

        // eslint-disable-next-line
    }, [customers]);


    useEffect(() => {
        if (!debouncedFilter) {
            setFiltered(customers);
        }
        else {
            const columnNames = columns.map(c => c.dataField);
            const filtered = (customers ?? []).filter(m => {
                const searchValue = debouncedFilter.toLowerCase();
                const values = Object.entries(m).filter(([k, v]) => {
                    return columnNames.includes(k);
                }
                )
                return values.some(([k, v]) => String(v).toLowerCase().indexOf(searchValue) >= 0);

            })
            setFiltered(filtered);
        }
    }, [debouncedFilter, customers, columns]);

    const handleNextPage = ({ page, onPageChange }) => () => {
        onPageChange(page + 1);
    };

    const handlePrevPage = ({ page, onPageChange }) => () => {
        onPageChange(page - 1);
    };

    if (!customers || !isIterableArray(columns)) {
        return null;
    }


    const tableOptions = {
        onClick: (e, row, rowIndex) => {
            history.push(`/customer/${row.customer_id}`);
        }
    }

    return (
        <ToolkitProvider
            keyField="customer_id"
            data={(filtered ?? [])}
            columns={columns}
            exportCSV={{
                fileName: 'kunder.csv',
                ignoreFooter: true,
                onlyExportFiltered: true,
                exportAll: false
            }
            } >
            {props => (<div>
                <Row>
                    <Col style={{ minWidth: '200px' }}>
                        <Input
                            type="search"
                            autoFocus
                            placeholder="Filter"
                            aria-label="Filter"
                            className="rounded-pill search-input"
                            value={filter}
                            onChange={({ target }) => setFilter(target.value)}
                        />
                        <FormText className="ml-4" muted>{`${filtered?.length} / ${customers?.length} Kunder`}</FormText>
                    </Col>
                    <Col xs="auto">
                        <MyExportCSV {...props.csvProps} />
                    </Col>
                </Row>
                <Button onClick={() => { history.push(`/customer/new`); }} className="m-2 fs--1 rounded-pill" size="sm" color="outline-primary">
                    <FontAwesomeIcon icon="plus" />
                    {" Legg til"}
                </Button>
                <PaginationProvider pagination={paginationFactory({
                    custom: true,
                    sizePerPage: 20,
                    totalSize: isIterableArray(filtered) && filtered.length
                })}>
                    {({ paginationProps, paginationTableProps }) => {
                        const lastIndex = paginationProps.page * paginationProps.sizePerPage;

                        return (<>
                            <div className="table-responsive  mb-4">
                                <BootstrapTable
                                    {...props.baseProps}
                                    hover
                                    bordered={false}
                                    classes="table-dashboard table-striped table-sm fs--1 border-bottom border-left border-right border-200"
                                    rowClasses="btn-reveal-trigger border-top border-200 cursor-pointer"
                                    headerClasses="bg-200 text-900 border-y border-200"
                                    rowEvents={tableOptions}
                                    defaultSorted={[{ dataField: 'name', order: 'asc' }]}
                                    {...paginationTableProps}

                                />
                            </div>
                            {isIterableArray(filtered) && filtered.length > 20 &&
                                <Row noGutters className="px-1 py-3 flex-center">
                                    <Col xs="auto">
                                        <Button
                                            color="falcon-default"
                                            size="sm"
                                            onClick={handlePrevPage(paginationProps)}
                                            disabled={paginationProps.page === 1}
                                        >
                                            <FontAwesomeIcon icon="chevron-left" />
                                        </Button>
                                        {getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage).map(pageNo => (
                                            <Button
                                                color={paginationProps.page === pageNo ? 'falcon-primary' : 'falcon-default'}
                                                size="sm"
                                                className="ml-2"
                                                onClick={() => paginationProps.onPageChange(pageNo)}
                                                key={pageNo}
                                            >
                                                {pageNo}
                                            </Button>
                                        ))}
                                        <Button
                                            color="falcon-default"
                                            size="sm"
                                            className="ml-2"
                                            onClick={handleNextPage(paginationProps)}
                                            disabled={lastIndex >= paginationProps.totalSize}
                                        >
                                            <FontAwesomeIcon icon="chevron-right" />
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </>
                        );
                    }}

                </PaginationProvider>
            </div>)}
        </ToolkitProvider>
    );
}

export default Customers;