import { Row, Col, Label } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { Alert, Button, Input, FormText, Nav, NavItem, NavLink } from 'reactstrap';
import { useDebounce } from '../../../helpers/utils';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { isIterableArray, getPaginationArray } from '../../../helpers/utils'
import csvtojson from "csvtojson";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import { createResident } from '../../../data/dataUpdate';
import AddressMap from '../map/AddressMap';
import Select from 'react-select';
import { fetchCustomers, fetchCustomerBuildings } from '../../../data/dataFetch';




const Residents = ({ residents }) => {

    const [columns, setColumns] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [filter, setFilter] = useState();
    const debouncedFilter = useDebounce(filter, 300);
    const history = useHistory();
    const [importCSV, setImportCsv] = useState(false);
    const [activeTab, setActiveTab] = useState("list");
    const [importBuilding, setImportBuilding] = useState();    
    const [importMessage, setImportMessage] = useState();
    const [customers, setCustomers] = useState(null);
    const [customerBuildings, setCustomerBuildings] = useState(null);


    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <>
                <div>
                    <Button className="float-right mb-2" color="success" onClick={handleClick}>Eksporter til CSV</Button>
                </div>
                <div>
                    <Button className="float-right mb-2" color="success" onClick={() => { setImportCsv(true); }}>Importer fra CSV fil</Button>
                </div>
            </>
        );
    };

    useEffect(() => {
        let columns = [
            {
                dataField: 'first_name',
                text: 'Fornavn',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
            {
                dataField: 'last_name',
                text: 'Etternavn',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
            {
                dataField: 'login_id',
                text: 'login',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
            {
                dataField: 'box.box_address',
                text: 'Postkasse',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
            {
                dataField: 'email',
                text: 'Epost',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
            {
                dataField: 'phone',
                text: 'Telefone',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
            {
                dataField: 'pairing_code',
                text: 'Paringskode',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
        ];

        setColumns(columns);

        // eslint-disable-next-line
    }, [residents]);

    useEffect(() => {
        if (!debouncedFilter) {
            setFiltered(residents);
        }
        else {
            const columnNames = columns.map(c => c.dataField);
            const filtered = (residents ?? []).filter(m => {
                const searchValue = debouncedFilter.toLowerCase();
                const values = Object.entries(m).filter(([k, v]) => {
                    return columnNames.includes(k);
                }
                )
                return values.some(([k, v]) => String(v).toLowerCase().indexOf(searchValue) >= 0);

            })
            setFiltered(filtered);
        }
    }, [debouncedFilter, residents, columns]);

    useEffect(() => {
        const loadCustomers = async () => {
            setCustomers(await fetchCustomers());
        }
        loadCustomers();
    }, [])

    const handleNextPage = ({ page, onPageChange }) => () => {
        onPageChange(page + 1);
    };

    const handlePrevPage = ({ page, onPageChange }) => () => {
        onPageChange(page - 1);
    };

    if (!residents || !isIterableArray(columns)) {
        return null;
    }

    const loadCustomerBuildings = async (customerId) => {
        setCustomerBuildings(await fetchCustomerBuildings(customerId));
    }
    const handleImportClick = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async () => {
            const csvData = reader.result;
            const jsonArray = await csvtojson().fromString(csvData);
            var alertText = '';
            jsonArray.forEach(async element => {
                var resident = JSON.parse(JSON.stringify(element));
                resident.building_id = importBuilding;
                const result = await createResident(resident, true);
                if (result)
                    alertText += `\n beboer ${resident.Fornavn ?? resident.first_name}  ${resident.Etternavn ?? resident.last_name} er lagt til`;
                else
                    alertText += `\n feil ved import av beboer`;
                setImportMessage(alertText);
            });
        };
        reader.readAsText(file);
    };

    const tableOptions = {
        onClick: (e, row, rowIndex) => {
            history.push(`/resident/${row.resident_id}`);
        }
    }



    return (
        <>
            <Modal show={importCSV} size='lg'>
                <ModalHeader>Importer fra CSV fil</ModalHeader>
                <ModalBody>
                    <div className="mb-5">
                        <Label>Kunde</Label>
                        <Select
                            options={
                                isIterableArray(customers) && Object.values(customers).map((customer) => ({ value: customer.customer_id, label: customer.customer_name }))
                            }
                            onChange={({ value }) => {
                                loadCustomerBuildings(value);
                            }}
                        />
                        <Label>Bygning</Label>
                        <Select
                            options={
                                isIterableArray(customerBuildings) && Object.values(customerBuildings).map((building) => ({ value: building.building_id, label: building.building_name }))
                            }
                            onChange={({ value }) => {
                                setImportBuilding(value);
                            }}
                        />
                        <div style={{paddingTop: '15px'}}>
                            <Input type="file" onChange={handleImportClick} />
                            {importMessage && <Alert color="Warning">{(importMessage ?? '').split('\n').map(str => <p>{str}</p>)}</Alert>}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="warning"
                        onClick={() => {
                            setImportCsv(false);
                            setImportMessage(null);
                        }}>
                        Avbryt
                    </Button>
                </ModalFooter>
            </Modal>
            <ToolkitProvider
                keyField="resident_id"
                data={(filtered ?? [])}
                columns={columns}
                exportCSV={{
                    fileName: 'beboere.csv',
                    ignoreFooter: true,
                    onlyExportFiltered: true,
                    exportAll: false
                }
                } >
                {props => (<div>
                    <Row>
                        <Col style={{ minWidth: '200px' }}>
                            <Input
                                type="search"
                                autoFocus
                                placeholder="Filter"
                                aria-label="Filter"
                                className="rounded-pill search-input"
                                value={filter}
                                onChange={({ target }) => setFilter(target.value)}
                            />
                            <FormText className="ml-4" muted>{`${filtered?.length} / ${residents?.length} Postkasser`}</FormText>
                        </Col>
                        <Col xs="auto">
                            <MyExportCSV {...props.csvProps} />
                        </Col>
                    </Row>
                    <Button onClick={() => { history.push(`/resident/new`); }} className="m-2 fs--1 rounded-pill" size="sm" color="outline-primary">
                        <FontAwesomeIcon icon="plus" />
                        {" Legg til"}
                    </Button>
                    <PaginationProvider pagination={paginationFactory({
                        custom: true,
                        sizePerPage: 20,
                        totalSize: isIterableArray(filtered) && filtered.length
                    })}>
                        {({ paginationProps, paginationTableProps }) => {
                            const lastIndex = paginationProps.page * paginationProps.sizePerPage;

                            return (
                                <>
                                    <Nav tabs>
                                        <NavItem className='cursor-pointer'>
                                            <NavLink active={activeTab === 'list'} onClick={() => setActiveTab('list')}>Liste</NavLink>
                                        </NavItem>
                                        <NavItem className='cursor-pointer'>
                                            <NavLink active={activeTab === 'map'} onClick={() => setActiveTab('map')}>Kart</NavLink>
                                        </NavItem>

                                    </Nav>
                                    {activeTab === 'list' && <div className="table-responsive  mb-4">
                                        <BootstrapTable
                                            {...props.baseProps}
                                            hover
                                            bordered={false}
                                            classes="table-dashboard table-striped table-sm fs--1 border-bottom border-left border-right border-200"
                                            rowClasses="btn-reveal-trigger border-top border-200 cursor-pointer"
                                            headerClasses="bg-200 text-900 border-y border-200"
                                            rowEvents={tableOptions}
                                            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
                                            {...paginationTableProps}

                                        />
                                    </div>}
                                    {activeTab === 'list' && isIterableArray(filtered) && filtered.length > 20 &&
                                        <Row noGutters className="px-1 py-3 flex-center">
                                            <Col xs="auto">
                                                <Button
                                                    color="falcon-default"
                                                    size="sm"
                                                    onClick={handlePrevPage(paginationProps)}
                                                    disabled={paginationProps.page === 1}
                                                >
                                                    <FontAwesomeIcon icon="chevron-left" />
                                                </Button>
                                                {getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage).map(pageNo => (
                                                    <Button
                                                        color={paginationProps.page === pageNo ? 'falcon-primary' : 'falcon-default'}
                                                        size="sm"
                                                        className="ml-2"
                                                        onClick={() => paginationProps.onPageChange(pageNo)}
                                                        key={pageNo}
                                                    >
                                                        {pageNo}
                                                    </Button>
                                                ))}
                                                <Button
                                                    color="falcon-default"
                                                    size="sm"
                                                    className="ml-2"
                                                    onClick={handleNextPage(paginationProps)}
                                                    disabled={lastIndex >= paginationProps.totalSize}
                                                >
                                                    <FontAwesomeIcon icon="chevron-right" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                    {activeTab === 'map' &&
                                        <div>
                                            <AddressMap
                                                addresses={
                                                    Object.values(residents.map(value => {
                                                        return {
                                                            address: (value.street ?? '') + ' ' + (value.zip ?? '') + ' ' + (value.city ?? ''),
                                                            name: value.first_name + ' ' + value.last_name
                                                        }
                                                    }).reduce((acc, curr) => {
                                                        if (!acc[curr.address]) {
                                                            acc[curr.address] = {
                                                                address: curr.address,
                                                                name: curr.name,
                                                            };
                                                        } else {
                                                            acc[curr.address].name = `${acc[curr.address].name} , ${curr.name}`;
                                                        }
                                                        return acc;
                                                    }, {}))
                                                }
                                            />
                                        </div>

                                    }
                                </>
                            );
                        }}

                    </PaginationProvider>
                </div>)}
            </ToolkitProvider>
        </>
    );
}

export default Residents;