import { AppProvider } from './context/appContext/appContext';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import AuthState from "./context/authContext/AuthState";

function App() {
  return (
    <AppProvider>
      <AuthState>
        <Router basename={process.env.PUBLIC_URL} fallback={<span />}>
          <Layout />
        </Router>
      </AuthState>
    </AppProvider>
  );
}
export default App;
